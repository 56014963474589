<template>
  <div tracking-download>
    <label>{{ $t('signUp.success.download') }}</label>
    <img src="/img/img_download.png" alt="Download" />
    <ColorButton type="light-red" @click="download">
      {{ $t('signUp.success.download') }}
      <FontIcon :name="type[osType]" class="down-icon" />
    </ColorButton>
    <section>
      <span>{{ $t('signUp.availableOn') }}</span>
      <FontIcon v-for="item in Object.keys(type)" :name="type[item]" :key="item" />
    </section>
  </div>
</template>

<script>
import FontIcon from "@shared/components/common/FontIcon.vue";
import ColorButton from "@shared/components/common/ColorButton.vue";
import { state } from "@shared/utils/storeUtils.mjs";

export default {
  name: "TrackingDownload",
  components: { FontIcon, ColorButton },
  computed: {
    isMobile: state('browser', 'mobile'),
    downloadUrl: state('env', `downloadUrl`),
    osType() {
      if (this.isMobile) return window.navigator.userAgent.includes('Android') ? 'android' : 'ios';
      return window.navigator.userAgent.includes('Mac') ? 'mac' : 'windows';
    }
  },
  data() {
    return {
      type: {
        windows: 'brands-windows',
        ios: 'display',
        mac: 'brands-apple',
        android: 'brands-android',
      }
    };
  },
  methods: {
    download() {
      window.open(this.downloadUrl, '_blank');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tracking-download] { .flex(); .align-center(); .justify-center(); .flex-dc();
  >label { .fs(32); .tc(); .pt(80); .mt(50); .-t(@c-w03); .mb(20); }
  [color-button] { .wh(100%, 56); .br(28); .fs(18); .tr-t(.3s); .rel(); .max-w(472); .mh-c(); .block(); .mt(20); .mb(12);
    .down-icon { .abs(); .rt(16, 50%); .mt(-12); }
  }
  >section { .ml(56);
    [font-icon] { .ml(4); }
  }
  [font-icon] { .c(white); .fs(20); .vam(); }

  @media(@tl-up) {
    >label { .mt(40); .mb(40); .pt(0); .-t(); }
    [color-button] { .mt(26);}
  }
}
</style>