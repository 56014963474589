<template>
  <div landing-footer v-if="content" :style="{paddingTop: `${height}px`}" ref="footer" :class="{'fixed': isFix}">
    <div :class="['body', {'ready': height}]" ref="body" v-html="$t(content, {year:getYear()})" :style="{width:`${width}px`}" />
  </div>
</template>

<script>
import { addResize, removeResize } from '@shared/utils/resizeUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { getYear } from '@shared/utils/timeUtils.mjs';

export default {
  name: 'LandingFooter',
  props: {
    content: { type: String, default: null },
  },
  data() {
    return {
      height: 0,
      width: 0,
      isFix: false,
    };
  },
  computed: {
    isMobile: state('browser', 'mobile'),
  },
  watch: {
    $route: 'updateSize',
  },
  methods: {
    updateSize() {
      this.width = window.innerWidth;
      this.$nextTick(() => {
        this.height = this.$refs.body?.clientHeight || 0;
        const containerHeight =  document.body.querySelector('.contents-holder')?.clientHeight ||  0;
        this.isFix = !this.isMobile && window.innerHeight > 800 && containerHeight <= window.innerHeight;
      });
    },
    getYear,
  },
  async mounted() {
    window.addEventListener('resize', this.updateSize);
    addResize(document.body.querySelector('.contents-holder'), this.updateSize);
    // this.updateSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize);
    removeResize(document.body.querySelector('.contents-holder'), this.updateSize);
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
.GOLD {
  [landing-footer] { .bgc(transparent); }
}
[landing-footer] { .mt(80); .bgc(#171717); .rel();
  .body { .-t(rgba(200, 200, 200, 0.1)); .p(20); .abs(); .tl(); .lt(50%, 0); .t-xc(); .z(5); .fs(14); .w(100%); .o(0);
    &.ready { .o(1); }
    > a { .c(@c-link); }
  }
  @media (@ml-up) {
    .body { .p(20);
    }
  }
  &.fixed { .fix(); .lb(0, 0); .w(100%); .z(9);
    .body { }
  }
}
</style>