<template>
  <div landing-header :class="[landingMode, channelingName && 'warp']">
    <img :src="logo" alt="LOGO">
    <div v-if="landingMode === 'warp' || channelingName">
      <button class="btn-close" @click="close" v-if="landingMode === 'warp'">
        <FontIcon name="close" />
      </button>
      <div class="selector" v-if="regionList.length > 1">
        <div class="holder">
          <label>{{ $t('regions') }}</label>
          <DropSelect :list="regionList" theme="dark" @change="updateRegion" :translate="false" :value="currentRegion" />
        </div>
      </div>
    </div>
    <div v-else-if="landingMode === 'landing'" class="landing-selector">
      <DropSelect :value="lang" :list="langList" @change="updateLang" theme="dark" :translate="false" auto />
      <FontIcon name="earth-americas" />
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { baseList } from '@/constants/base/siteMap.mjs';

export default {
  name: 'LandingHeader',
  components: { DropSelect, FontIcon },
  props: {
    langList: Specific,
    landingMode: String,
    regionList: Specific,
    currentRegion: Specific
  },
  computed: {
    logo: state('env', 'logo'),
    site: state('env', 'site'),
    originSite: state('query', 'originId'),
    closeUrl: state('query', 'closeUrl'),
    channelingName: state('query', 'channelingName'),
  },
  data() {
    return {};
  },
  methods: {
    updateLang(lang) {
      this.$changeLocale(lang);
      this.$services.cookie.setLanguage(lang);
    },
    updateRegion(url) {
      const signup = process.env.VUE_APP_ENV === 'production' ? 'signup.' : 'signup-';
      location.href = `https://${signup}${url}`;
    },
    close() {
      if (this.closeUrl) {
        if(this.closeUrl === 'home') location.href = baseList.find(o => o.site === (this.originSite || this.site)).webUrl;
        else this.$router.back();
      } else {
        this.$router.back();
      }
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
.RTL {
  [landing-header] {
    &.landing {
      .landing-selector { .r(auto); .l(20); }
    }
    &.warp {
      > div {
        .btn-close { .r(auto); .l(20); }
      }
      .selector { }
    }
  }
}
.GOLD {
  [landing-header] { .bgc(#21180f); .-b(@c-gold);
    [drop-select] { .br(4); .-a(@c-gold); .bgc(@c-b03);}
    &.warp {
      > div {
        .btn-close {
          [font-icon] { .c(@c-gold); }
        }
        .selector { .bgc(transparent); }
      }
    }
  }
}
[landing-header] { .p(12, 20); .-b(@c-red); .fix(); .w(100%); .lt(0, 0); .bgc(#171717); .z(8);
  [drop-select] {.bgc(@c-w01); .-a(@c-w01);
    input[type='text'] { .fs(14); .lh(normal); }
  }
  &.landing {
    .landing-selector { .max-w(200); .abs(); .rt(20, 100); .t-yc();
      [drop-select] {
        a input { .pl(30) ; }
      }
      > [font-icon] { .ib(); .fs(16); .abs(); .lt(8, 50%); .t-yc(); .c(@c-w05); }
    }
  }
  &.warp {
    > div { .abs(); .wh(100%, 100%); .lt(0, 0); .z(2);
      .btn-close { .abs(); .rt(20, 50%); .t-yc();
        [font-icon] { .fs(20); .c(white); }
      }
      .selector { .abs(); .wh(100%,50); .b(-52); .bgc(#171717);
        .holder { .flex(); .items-center(); .abs(); .rt(20,50%); .t-yc();
          > label { .medium(); .fs(14); .mr(12); }
          [drop-select] { .w(240); }
        }
      }
    }
  }
  img { .block(); .h(40); .mh-c(); }

  @media(@tp-up) {
    &.landing {
      .landing-selector { .t(50%); }
    }
  }
}
</style>